<script>
import { GET_DASHBOARD_LAST_UPDATE } from '../gql-requests';

export default {
  name: 'LastUpdate',
  props: {
    dashboardName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tablesUpdates: null,
    };
  },
  methods: {
    getLastUpdate() {
      return this.tablesUpdates?.last_update?.split('T')?.shift();
    },
    isUpToDate() {
      const dateToCompare = this.dashboardName !== 'Weekly MTD'
        ? new Date()
        : this.getLastTuesday();

      return this.getLastUpdate() === dateToCompare.toISOString().split('T').shift();
    },
    getLastTuesday() {
      const lastTuesday = new Date();
      const day = lastTuesday.getDay();
      const diff = (day >= 2) ? day - 2 : day + 5;
      lastTuesday.setDate(lastTuesday.getDate() - diff);

      return lastTuesday;
    },
    getState() {
      if (!this.tablesUpdates) {
        return '';
      }

      return this.isUpToDate() ? 'is-success' : 'is-danger';
    },
  },
  apollo: {
    tablesUpdates: {
      query: GET_DASHBOARD_LAST_UPDATE,
      variables() {
        return {
          dashboardName: this.dashboardName,
        };
      },
    },
  },
};
</script>

<template>
  <div>
    <b-taglist attached>
      <b-tag type="is-light">
        <b>{{ dashboardName }}</b> last update
      </b-tag>
      <b-tag :type="getState()">
        <b-icon
          v-if="!tablesUpdates"
          pack="fas"
          icon="sync-alt"
          custom-class="fa-spin"
        />
        {{ getLastUpdate() }}
      </b-tag>
    </b-taglist>
  </div>
</template>
